import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import NoData from "./NoData";
import { ReactComponent as Reconnect } from "./assets/Reconnect.svg";
import { ReactComponent as ReconnectDisabled } from "./assets/ReconnectDisabled.svg";
import { ReactComponent as Sync } from "./assets/Sync.svg";
import { ReactComponent as GoogleAnalyticsIcon } from "./assets/GoogleAnalyticsIcon.svg";
import { ReactComponent as GoogleSheetsIcon } from "./assets/GoogleSheetsIcon.svg";
import { ReactComponent as Google } from "assets/svg/platforms/icons8-google.svg";
import { ReactComponent as Edit } from "./assets/Edit.svg";
import { ReactComponent as Copy } from "./assets/Copy.svg";
import { ReactComponent as CloseImg } from "assets/svg/close.svg";
import { ReactComponent as WarningIcon } from "assets/svg/warning-icon.svg";
import { ReactComponent as Lock } from "assets/svg/lock.svg";
import Loader from "../../../../assets/Gif/CommonLoader.gif";
import { ReactComponent as Delete } from "assets/svg/delete.svg";
import { getWarningMessage } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import ModalWrapper from "components/Modal/ModalWrapper";
import ModalContainer from "components/Modal/ModalContainer";
import { SelectField } from "Pages/ThirdPartyAttribution/Components/selectProperties";
import {
  getConfigsOfGAAccount,
  getPropertiesOfGAAccount,
  getThirdPartyAccounts,
  setThirdPartyAccount,
  updateConfigDetailsFromAccountId,
  getTokenStatusBasedOnBrandId,
  deleteToken,
  updatePrimaryTokenForThirdParty,
  getGoogleSheetsResyncData,
  setError,
} from "../../../../store/actions/thirdPartyChannels";
import moment from "moment";
import SyncHistoryModal from "./GoogleSheets/SyncHistoryModal";
import GoogleSheetsModal from "./GoogleSheets/GoogleSheetsModal";
import { CustomizedSnackBars } from "components/shared/AlertSnackBar/AlertSnackBar";
import { ReactComponent as Tick } from "./assets/Tick.svg";
import { ReactComponent as Error } from "./assets/Error.svg";
import LoaderGif from "../../../ThirdPartyAttribution/Assets/loader.gif";
import { isAdminRole, isViewerRole } from "utils/commonFunctions";
import { useSearchParams } from "react-router-dom-v5-compat";

interface Props {
  href: any;
  updateRows: any;
  rows: any;
  isLoading: boolean;
  setIsLoading: any;
}

const ThirdPartyTable = (props: Props) => {
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { brandRoles } = adaccount;

  const { selectedBrand: { id: brand_id = "", name: brandName = "" } = {} } =
    adaccount;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <PlatformTable {...props} brandId={brand_id} />
    </Box>
  );
};

const PlatformTable = ({
  brandId,
  href,
  updateRows,
  rows,
  isLoading,
  setIsLoading,
}: any) => {
  const dispatch = useDispatch();
  const [showConfigurationModal, setShowConfigurationModal] =
    useState<any>(false);
  const [showEditPropertyModal, setShowEditPropertyModal] =
    useState<any>(false);
  const [showGoogleSheetsModal, setShowGoogleSheetsModal] =
    React.useState<any>(false);
  const [gSheetModalType, setGSheetModalType] = React.useState<any>("edit");
  const [selectedGSheetRowData, setSelectedGSheetRowData] = useState();
  const [showSyncHistoryModal, setShowSyncHistoryModal] = useState<any>(false);
  const [showTokenTable, setShowTokenTable] = useState<any>(false);
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  // const isAdmin = userInfo?.roles?.includes("super-admin");
  // const [rows, updateRows] = useState([]);
  const [tokenData, setTokenData] = useState<any>([]);
  const [showResyncToast, setShowResyncToast] = useState(false);
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const [showAPIErrorToast, setShowAPIErrorToast] = useState(false);
  const [isResyncSuccess, setIsResyncSuccess] = useState(true);
  const [isCopySuccess, setIsCopySuccess] = useState(true);
  // const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");

  const handleSnackBarClose = (type: string) => {
    if (type === "resync") {
      setShowResyncToast(false);
    } else if (type === "copyToClipboard") {
      setShowCopiedToast(false);
    } else {
      dispatch(setError({ isError: false, error: {} }));
      setShowAPIErrorToast(false);
    }
  };
  const thirdPartyChannelsState = useSelector(
    (state: any) => state.thirdPartyChannels
  );

  const adaccountState = useSelector((state: any) => state.adaccount);
  const { brandRoles } = adaccountState;

  async function copyContent(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopySuccess(true);
      setShowCopiedToast(true);
    } catch (err) {
      setIsCopySuccess(false);
    }
  }
  useEffect(() => {
    dispatch(
      getTokenStatusBasedOnBrandId(
        { brandId: brandId, auth: "" },
        (resp: any) => {
          if (resp.data) setTokenData(resp.data.thirdPartyAccounts);
        }
      )
    );
  }, []);

  useEffect(() => {
    if (thirdPartyChannelsState?.error?.isError) {
      setShowAPIErrorToast(true);
    } else {
      setShowAPIErrorToast(false);
    }
  }, [thirdPartyChannelsState]);

  useEffect(() => {
    if (brandId) {
      setIsLoading(true);
      dispatch(
        getThirdPartyAccounts({
          brandId,
          callback: (value: any) => {
            setIsLoading(false);
            dispatch(setThirdPartyAccount(value));
            if (value?.data) updateRows(value?.data);
          },
        })
      );
    }
  }, [brandId, dispatch]);

  const styles = {
    textEllispis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tableDataFonts: {
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Inter",
      color: "#111827",
    },
  };

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "100px",
      }}
    >
      <img
        src={LoaderGif}
        alt="loading"
        style={{ height: "52px", width: "52px" }}
      />
    </Box>
  ) : !rows.length ? (
    <NoData type="third-party-table" />
  ) : (
    <Table sx={{ width: "100% !important" }}>
      {showSyncHistoryModal && (
        <SyncHistoryModal
          title="Sync History"
          subtitle="View Historical sync jobs and their status here."
          closeModal={() => {
            setShowSyncHistoryModal(false);
          }}
          sheetData={selectedGSheetRowData}
        />
      )}
      {showEditPropertyModal && (
        <EditProperty
          data={showEditPropertyModal}
          updateData={updateRows}
          closeModal={() => {
            setShowEditPropertyModal(false);
          }}
        />
      )}
      {showGoogleSheetsModal && (
        <GoogleSheetsModal
          title="Edit Google Sheets"
          subtitle="Edit data from your attribution platforms here. Use our standard sheet template"
          closeModal={() => {
            setShowGoogleSheetsModal(false);
          }}
          openedAs={gSheetModalType}
          editData={selectedGSheetRowData}
          updateRows={updateRows}
          setIsLoading={setIsLoading}
        />
      )}
      {showTokenTable && (
        <TokenTable
          setTokenData={setTokenData}
          data={showTokenTable}
          closeModal={() => {
            setShowTokenTable(false);
          }}
          brandId={brandId}
        />
      )}
      <Box sx={{ padding: "0 24px" }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "Inter",
                padding: "16px 16px 16px 0px !important",
              }}
              align="left"
            >
              Name
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", fontWeight: "700", fontFamily: "Inter" }}
              align="left"
            >
              ID
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", fontWeight: "700", fontFamily: "Inter" }}
              align="left"
            >
              Channel
            </TableCell>
            {/* {isAdmin && ( */}
            {isAdminRole(brandRoles) && (
              <TableCell
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                }}
                align="left"
              >
                Configuration
              </TableCell>
            )}
            <TableCell
              sx={{ fontSize: "12px", fontWeight: "700", fontFamily: "Inter" }}
              align="left"
            >
              Access/Active Tokens
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", fontWeight: "700", fontFamily: "Inter" }}
              align="left"
            >
              Sync History
            </TableCell>
            <TableCell
              sx={{
                fontSize: "12px",
                fontWeight: "700",
                fontFamily: "Inter",
                padding: "16px 0px 16px 16px",
              }}
              align="left"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => {
            const resyncDisabled = row?.config?.disable || !row?.accessible;
            const warning =
              tokenData?.length > 0
                ? getWarningMessage(
                    tokenData?.find((item: any) => item.adAccountId === row.id)
                      ?.tokens || []
                  )
                : {
                    className: "d-none",
                    message: "",
                    availableTokens: 0,
                  };
            return (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* column: name */}
                <TableCell
                  style={styles.tableDataFonts}
                  sx={{ width: "14%", padding: "16px 16px 16px 0px" }}
                  align="left"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <Box>
                      {row?.name}
                      {row?.channelName === "GOOGLE ANALYTICS" && (
                        <Typography
                          className={warning.className}
                          sx={{
                            height: "20px",
                            borderRadius: "4px",
                            color: "#E84B47",
                            fontSize: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "8px",
                            padding: "0px 5px",
                          }}
                        >
                          {warning.message}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                {/* column: id */}
                <TableCell sx={{ width: "16%" }} align="left">
                  <Stack direction="row" alignItems="center">
                    <Typography
                      title={row?.id?.length >= 10 && row?.id}
                      style={styles.tableDataFonts}
                      sx={{
                        maxWidth: "82px",
                        ...styles.textEllispis,
                      }}
                    >
                      {row?.id}
                    </Typography>
                    <Box sx={{ marginLeft: "5px" }}>
                      <Copy
                        cursor="pointer"
                        onClick={() => copyContent(row.id)}
                      />
                    </Box>
                  </Stack>
                </TableCell>
                {/* column: channel */}
                <TableCell
                  style={styles.tableDataFonts}
                  sx={{ width: "16%" }}
                  align="left"
                >
                  <Stack direction="row" alignItems="center">
                    {row?.channelName === "GOOGLE ANALYTICS" ? (
                      <GoogleAnalyticsIcon />
                    ) : (
                      <GoogleSheetsIcon />
                    )}
                    <Typography style={styles.tableDataFonts}>
                      {row?.channelName}
                    </Typography>
                  </Stack>
                </TableCell>
                {/* column: configuration  */}
                {/* {isAdmin && ( */}
                {isAdminRole(brandRoles) && (
                  <TableCell sx={{ width: "16%" }} align="left">
                    {(row.config && row.config?.utm) ||
                    (row.config && row.config?.sheetUrl) ? (
                      <Stack direction="row" alignItems="center">
                        <Box
                          sx={{
                            width: "134px",
                            height: "30px",
                            background: "rgba(18, 200, 178, 0.1)",
                            borderRadius: "4px",
                            border: " 1px solid #71DED1",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeigth: "400",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "auto 5px auto 0px",
                          }}
                        >
                          Configuration Added
                        </Box>
                        <Edit
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (row?.channelName === "GOOGLE SHEET") {
                              setGSheetModalType("edit");
                              setSelectedGSheetRowData(row);
                              setShowGoogleSheetsModal(true);
                            } else {
                              setShowEditPropertyModal(row);
                            }
                          }}
                        />
                      </Stack>
                    ) : (
                      <Typography
                        style={styles.tableDataFonts}
                        sx={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (row?.channelName === "GOOGLE SHEET") {
                            setGSheetModalType("add");
                            setShowGoogleSheetsModal(true);
                          } else {
                            setShowEditPropertyModal(row);
                          }
                        }}
                      >
                        Add Configuration
                      </Typography>
                    )}
                  </TableCell>
                )}
                {/* column: active tokens */}
                <TableCell sx={{ width: "16%" }} align="left">
                  {row?.channelName === "GOOGLE SHEET" ? (
                    <>
                      {row.accessible ? (
                        <Stack direction="row" alignItems="center">
                          <Tick />
                          &nbsp;&nbsp;
                          <Typography style={styles.tableDataFonts}>
                            Accessible
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Error style={{ height: "16px", width: "16px" }} />
                          &nbsp;&nbsp;
                          <Typography style={styles.tableDataFonts}>
                            Not Accessible
                          </Typography>
                        </Stack>
                      )}
                    </>
                  ) : (
                    <>
                      {tokenData?.find(
                        (item: any) => item.adAccountId === row.id
                      )?.tokens.length ? (
                        <Typography
                          style={styles.tableDataFonts}
                          sx={{
                            cursor: "pointer",
                            color: "#0869FB !important",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            setShowTokenTable({
                              brandId: brandId,
                              allTokenData: tokenData,
                              setTokenData: setTokenData,
                              channelName: row.channelId,
                              tokenData: tokenData?.find(
                                (item: any) => item.adAccountId === row.id
                              ),
                            });
                          }}
                        >
                          {`${warning.availableTokens} out of ${
                            tokenData?.find(
                              (item: any) => item.adAccountId === row.id
                            )?.tokens?.length
                          }`}
                        </Typography>
                      ) : (
                        <Typography style={styles.tableDataFonts}>0</Typography>
                      )}
                    </>
                  )}
                </TableCell>
                {/* column: sync history */}
                <TableCell sx={{ width: "16%" }} align="left">
                  <Stack direction="row" alignItems="center">
                    <Typography style={styles.tableDataFonts}>
                      {row?.lastStatus === "ERROR"
                        ? "Sync Issues"
                        : moment(row?.syncTime).format("DD, MMM YYYY")}
                    </Typography>
                    &nbsp;
                    {row?.channelName === "GOOGLE SHEET" && (
                      <Sync
                        cursor="pointer"
                        onClick={() => {
                          if (row?.channelName === "GOOGLE SHEET") {
                            setSelectedGSheetRowData(row);
                            setShowSyncHistoryModal(true);
                          }
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
                {/* column :Action */}
                <TableCell
                  style={styles.tableDataFonts}
                  sx={{ width: "16%", padding: "16px 0px 16px 16px" }}
                  align="left"
                >
                  {row?.channelName === "GOOGLE ANALYTICS" ? (
                    <a
                      style={{ textDecoration: "none" }}
                      href={href["google analytics"]}
                      target={isViewedFromBeta?'_parent':''}
                    >
                      <Button
                        startIcon={
                          <Google style={{ height: "18px", width: "18px" }} />
                        }
                        sx={{
                          width: "160px",
                          padding: "8px 12px",
                          background: "#ffffff",
                          borderRadius: "4px",
                          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                          textTransform: "none",
                          fontSize: "10px",
                          fontWeight: "600",
                          fontFamily: "Inter",
                          color: "#757575",
                        }}
                      >
                        Connect with Google
                      </Button>
                    </a>
                  ) : (
                    <Button
                      disabled={resyncDisabled}
                      onClick={() => {
                        const sheetId = row.id;
                        const sheetName = row.name;
                        dispatch(
                          getGoogleSheetsResyncData(
                            { brandId, sheetId, sheetName },
                            (response: any) => {
                              setShowResyncToast(true);
                              if (response?.success) {
                                setIsResyncSuccess(true);
                              } else {
                                setIsResyncSuccess(false);
                              }
                            }
                          )
                        );
                      }}
                      startIcon={
                        resyncDisabled ? <ReconnectDisabled /> : <Reconnect />
                      }
                      sx={{
                        width: "128px",
                        height: "32px",
                        border: resyncDisabled
                          ? "0.880427px solid #bdbdbd"
                          : "0.880427px solid #0869FB",
                        fontSize: "10px",
                        textTransform: "unset",
                      }}
                    >
                      Resync Data Now
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Box>
      <CustomizedSnackBars
        handleClose={() => handleSnackBarClose("resync")}
        open={showResyncToast}
        msg={
          isResyncSuccess
            ? "Resync request received"
            : "Resync request not received"
        }
        severity={isResyncSuccess ? "success" : "error"}
      />
      <CustomizedSnackBars
        handleClose={() => handleSnackBarClose("copyToClipboard")}
        open={showCopiedToast}
        msg={isCopySuccess ? "Id Copied" : "Unable to copy Id"}
        severity={isCopySuccess ? "success" : "error"}
      />
      <CustomizedSnackBars
        handleClose={() => handleSnackBarClose("apiError")}
        open={showAPIErrorToast}
        msg={"sheet id and sheet name alrady existis for selected brand"}
        severity={"error"}
      />
    </Table>
  );
};

const EditProperty = ({ closeModal, data, updateData }: any) => {
  const [value, setValue] = useState(data.config?.utm);
  const [edit, setEdit] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<any>({
    name: data.config?.propertyName,
    id: data.config?.propertyId,
  });
  const [selectedView, setSelectedView] = useState<any>({
    name: data.config?.viewName,
    id: data.config?.viewId,
  });
  const [viewData, updateViewData] = useState(null);
  const [propertiesData, updatePropertiesData] = useState<any>(null);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state.userInfoReducer);
  // const isAdmin = userInfo?.roles?.includes("super-admin");
  useEffect(() => {
    if (propertiesData)
      setSelectedProperty(
        propertiesData?.find((item: any) => item.id === selectedProperty.id)
      );
  }, [propertiesData]);

  useEffect(() => {
    if (data?.id) {
      dispatch(
        getPropertiesOfGAAccount({ gaAccountId: data.id }, (resp: any) => {
          // if (resp.data.length)
          updatePropertiesData(
            resp.data.map((item: any) => ({
              id: item.propertyId,
              name: item.propertyName,
              type: item.type,
            }))
          );
        })
      );
    }
  }, [dispatch, data.id]);

  const handleView = () => {
    updateViewData(null);
    setSelectedView(null);
    dispatch(
      getConfigsOfGAAccount(
        {
          gaAccountId: data.id,
          propertyId: selectedProperty?.id,
        },
        (resp: any) => {
          // if (resp.data.length)
          updateViewData(
            resp.data.map((item: any) => ({
              id: item.viewId,
              name: item.viewName,
            }))
          );
        }
      )
    );
  };

  useEffect(() => {
    if (selectedProperty?.id && data.id) {
      dispatch(
        getConfigsOfGAAccount(
          {
            gaAccountId: data.id,
            propertyId: selectedProperty?.id,
          },
          (resp: any) => {
            // if (resp.data.length)
            updateViewData(
              resp.data.map((item: any) => ({
                id: item.viewId,
                name: item.viewName,
              }))
            );
          }
        )
      );
    }
  }, [data.id, selectedProperty?.id]);

  const adaccountState = useSelector((state: any) => state.adaccount);
  const brandId = adaccountState.selectedBrand.id;
  const { brandRoles } = adaccountState;

  return (
    <ModalWrapper>
      <ModalContainer
        className="third-party-modal"
        title="Google Analytics Configuration"
        subtitle="Edit Property and View Details. Additionally view information about data sync."
        width="520px"
        handleClose={() => {
          closeModal();
        }}
      >
        <Box>
          <SelectField
            heading="Property"
            selectedValue={selectedProperty}
            changeSelectedValue={setSelectedProperty}
            data={propertiesData}
            onChange={handleView}
          />
          {selectedProperty?.type === "UA" && (
            <SelectField
              heading="View"
              selectedValue={selectedView}
              changeSelectedValue={setSelectedView}
              data={viewData}
            />
          )}
        </Box>
        {/* {isAdmin && ( */}
        {isAdminRole(brandRoles) && (
          <Box>
            <Typography
              fontSize="14px"
              fontFamily="Inter"
              fontWeight="400"
              color="#666666"
            >
              UTM Configuration
            </Typography>
            <Box
              sx={{
                padding: "11px 16px",
                margin: "6px 0px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                minHeight: "190px",
              }}
            >
              <textarea
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0px",
                  minHeight: "190px",
                  outline: "none",
                  resize: "none",
                  color: "#999",
                }}
                placeholder="Type here..."
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ width: "100%" }}>
          <Button
            disabled={
              !(
                selectedProperty &&
                (selectedProperty?.type !== "UA"
                  ? selectedProperty?.type !== "UA"
                  : selectedView)
              )
              // || isViewerRole(brandRoles)
            }
            onClick={() => {
              const gaConfigDetailsPayload = {
                propertyName: selectedProperty?.name,
                viewName: selectedView?.name,
                propertyId: selectedProperty?.id,
                viewId: selectedView?.id,
                utm: value || undefined,
              };
              dispatch(
                updateConfigDetailsFromAccountId(
                  { brandId, gaAccountId: data.id },
                  gaConfigDetailsPayload,
                  (resp: any) => {
                    if (resp.success) {
                      dispatch(
                        getThirdPartyAccounts({
                          brandId,
                          callback: (value: any) => {
                            dispatch(setThirdPartyAccount(value));
                            if (value?.data) updateData(value?.data);
                          },
                        })
                      );
                    }
                  }
                )
              );
              closeModal();
            }}
            sx={{
              background: "#0869FB",
              padding: "10px 16px",
              float: "right",
              margin: "4px 0px",
              color: "white",
              borderRadius: "8px",
              fontSize: "12px",
              fontWeight: "600",
              fontFamily: "Inter",
              textTransform: "unset",
              "&:hover": { background: "#0869FB" },
              ":disabled": { opacity: "0.5", color: "white" },
            }}
          >
            Save
          </Button>
        </Box>
      </ModalContainer>
    </ModalWrapper>
  );
};

const TokenTable = ({ closeModal, data, setTokenData, brandId }: any) => {
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const dispatch = useDispatch();
  const [tokens, setTokens] = useState(data?.tokenData?.tokens);
  const [loading, setLoading] = useState(false);

  const setPrimaryKey = (tokenData: any) => {
    dispatch(
      updatePrimaryTokenForThirdParty(
        {
          tokenUID: tokenData.tokenUID,
          accountId: tokenData.adAccountId,
          params: {},
        },
        () => {
          dispatch(
            getTokenStatusBasedOnBrandId({ brandId, auth: "" }, (resp: any) => {
              if (resp.data) {
                setTokenData([...resp.data.thirdPartyAccounts]);
                const newAllTokensData = [...resp.data.thirdPartyAccounts];
                for (let i = 0; i < newAllTokensData.length; i++) {
                  if (
                    newAllTokensData[i].adAccountId ===
                    data.tokenData.adAccountId
                  ) {
                    setTokens(newAllTokensData[i].tokens);
                    break;
                  }
                }
              }
              setLoading(false);
            })
          );
        }
      )
    );
  };

  const deleteActiveToken = (token: any) => {
    dispatch(
      deleteToken(
        {
          params: {
            accountId: token.adAccountId,
            tokenUID: token.tokenUID,
            auth: "",
          },
          accountId: token.adAccountId,
          tokenUID: token.tokenUID,
        },
        (resp: any) => {
          if (resp.success) {
            const newTokens = tokens.filter(
              (item: any) => item.tokenUID !== token.tokenUID
            );
            setTokens(newTokens);
            const newAllTokensData = [...data.allTokenData];
            for (let i = 0; i < newAllTokensData.length; i++) {
              if (
                newAllTokensData[i].adAccountId === data.tokenData.adAccountId
              ) {
                newAllTokensData[i].tokens = newTokens;
                break;
              }
            }
            data.setTokenData(newAllTokensData);
          }
        }
      )
    );
  };
  const platforms = useSelector((state: any) => state.adaccount.platforms);
  return (
    <div
      className="account-manager-modal token-modal-container"
      style={{ left: isViewedFromBeta ? "0" : "5%" }}
    >
      <div className="token-modal">
        <div className="token-header">
          <div>
            <span>{data?.tokenData?.adAccountName}</span>
            <span className="dot" />
            <span>
              {
                platforms?.find(
                  (item: any) => item.channelId === data.channelName
                )?.type
              }
            </span>
          </div>
          <CloseImg
            className="close-icon"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
        </div>
        <div className="token-account-id">{data?.tokenData?.adAccountId}</div>
        <div className="token-table">
          <div className="table-head">
            <div className="table-row">
              <div>
                User &ensp;
                {/* <UpDownArrow onClick={() => _handleSort("platformUserId")} /> */}
              </div>

              <div>Token Status &ensp;</div>
              <div>
                Connected On &ensp;
                {/* <UpDownArrow onClick={() => _handleSort("accountName")} /> */}
              </div>
              <div>Action &ensp;</div>
            </div>
          </div>
          <div
            className="table-body"
            style={{
              display: `${loading ? "flex" : "block"}`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <img
                alt="loader"
                style={{ height: "70px", width: "70px" }}
                src={Loader}
              />
            ) : (
              tokens?.length &&
              tokens?.map((token: any) => {
                return (
                  <div
                    key={token.platformUserEmail}
                    className={`table-row  ${
                      token.isPrimary ? "primary-token" : ""
                    }`}
                  >
                    <div className="token-owner">
                      <span>{token.platformUserEmail}</span>
                      <div className="lock-container">
                        <div className="lock-tooltip">Primary Token</div>
                        <Lock
                          className="lock-icon"
                          onClick={() => {
                            if (!token.isPrimary) {
                              setLoading(true);
                              setPrimaryKey(token);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <TokenAccess token={token} />
                    </div>
                    <div>
                      {moment(token.connectedOn).local().format("MMM DD,YYYY")}
                    </div>
                    {token.isPrimary || tokens.length === 1 ? (
                      <div />
                    ) : (
                      <div className="delete-container">
                        <div className="delete-tooltip">Delete This Token</div>
                        <Delete
                          className="delete-icon"
                          onClick={() => {
                            deleteActiveToken(token);
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function TokenAccess({ token }: { token: any }) {
  const [warnings, setWarnings] = useState<any>({});

  useEffect(() => {
    const _warnings = getWarningMessage([token]);
    setWarnings(_warnings);
  }, []);
  return (
    <div className={`token-access-stat token-${warnings.className}`}>
      {warnings.message ? (
        <>
          <WarningIcon />
          {warnings.message}
        </>
      ) : (
        "Active"
      )}
    </div>
  );
}

export default ThirdPartyTable;
